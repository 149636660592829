<template>
  <!-- <div id="ProjectBar" @scroll="scrollEvent" ref="ProjectBar"> -->
  <div id="ProjectBar" ref="ProjectBar">
    <!--用于设置跳转锚点-->
    <div id="topAnchor"></div>
    <!--返回-->
    <div class="getBack" @click="getBackDir" v-if="PageState == 1">
      <i class="ivu-icon ivu-icon-ios-arrow-back"></i>
      <span>返回</span>
    </div>
    <div class="newBox">
      <!--搜索项目-->
      <div class="searchProject">
        <!-- <i class="ivu-icon ivu-icon-ios-search"></i> -->
        <Input
          type="text"
          v-model.trim="searchMsg"
          placeholder="搜索任务或文件夹"
          search
          @on-search="searchProjectFn"
        />
      </div>
      <div>
        <div
          :class="$CSXF ? 'btn' : 'btn borderRadius'"
          @click="newTaskFn"
          v-show="listData.length > 0 || dirID != -1"
        >
          <span style="color: #fff">
            <Icon
              type="md-list-box"
              size="16"
              color="#fff"
              style="margin-right: 6px"
            />新建任务
          </span>
        </div>
        <div
          :class="$CSXF ? 'btn' : 'btn borderRadius'"
          @click="addFolderBox = true"
          v-show="dirID == -1"
        >
          <span>
            <Icon
              type="ios-folder"
              size="16"
              :color="$store.state.darkTheme ? '#fff' : '#000'"
              style="margin-right: 6px"
            />新建文件夹
          </span>
        </div>
      </div>
    </div>

    <!--项目显示-->
    <div class="ProjectList">
      <!-- <h1>最新 <i class="ivu-icon ivu-icon-ios-arrow-round-down"></i></h1> -->
      <div v-if="noData" class="noData">
        <h1>暂无数据</h1>
      </div>
      <!--项目-->
      <Spin size="large" style="margin-top: 30px" fix v-if="loading"></Spin>
      <div v-else>
        <div
          class="ListItem"
          v-for="(item, ind) in listData"
          :key="item.ind"
          :style="`z-index: ${300 - ind}`"
        >
          <div class="ItemLeft" @click="IntoDir(item.team_id, item.id)">
            <Icon type="md-folder" color="#c9caca" size="60" />
          </div>
          <div class="ItemContent">
            <h2 :title="item.dir_name" @click="IntoDir(item.team_id, item.id)">
              {{ item.dir_name }}
            </h2>
            <!-- <div class="ItemRight"
                 v-if="TeamID == user_info.team_id">
              <Poptip placement="bottom-end">
                <i class="more_i ivu-icon ivu-icon-md-more"></i>
                <div class="api"
                     slot="content">
                  <div class="Button"
                       @click="editShou(item)">
                    重命名
                  </div>
                  <div class="Divider"></div>
                  <div class="Button"
                       @click="delete_Level_2(item)">
                    删除
                  </div>
                </div>
              </Poptip>
            </div> -->
            <div class="more" @click="showMore(ind)">
              <Icon type="md-more" size="22" />
              <div :style="{ display: moreAct == ind ? 'block' : 'none' }">
                <p @click.stop="editShou(item)">
                  <Icon type="md-create" style="margin-right: 0.15rem" />重命名
                </p>
                <p @click.stop="delete_Level_2(item)">
                  <Icon type="ios-trash" style="margin-right: 0.15rem" />删除
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-show="dirID === -1 && taskData.length > 0" class="near">
          最近添加
        </div>
        <div
          class="taskItem"
          v-for="(item, ind) in taskData"
          :key="item.ind"
          :style="`z-index: ${200 - ind}`"
        >
          <div class="ItemLeft" @click="IntoheTask(item)">
            <span>{{ item.tasktype == 'airroute' ? '航线' : '建图' }}</span>
            <img :src="item.surface_plot" v-if="item.surface_plot" />
            <!-- <img src="../../assets/img/Marker/line_green.png"
                 v-else-if="item.tasktype == 'airroute'" />
            <img src="../../assets/img/Marker/pic_red.png"
                 v-else-if="item.tasktype == 'figure'" /> -->
            <p v-else>该任务无截图</p>
            <!-- 当前时间戳大于data_update_time加24小时的话隐藏新照片提示 -->
            <div
              class="newImgTip"
              v-show="
                new Date().getTime() < item.data_update_time
                  ? new Date(
                      item.data_update_time.replace(/-/g, '/')
                    ).valueOf() + 86400
                  : 0
              "
            >
              新照片
            </div>
          </div>
          <div class="ItemContent" @click="IntoheTask(item)">
            <h2 :title="item.filetitle">
              {{ item.filetitle }}
            </h2>
            <h3>
              {{ item.data_update_time }}
            </h3>
          </div>
          <!-- <div class="ItemRight">
            
            <Poptip placement="bottom-end">
              <i class="more_i ivu-icon ivu-icon-md-more"></i>
              <div class="api"
                   slot="content">
               
                <div class="Button"
                     @click="send_Task(item)">
                  派发
                </div>
                <div class="Divider"
                     v-if="TeamID == user_info.team_id"></div>
                <div class="Button"
                     v-if="TeamID == user_info.team_id"
                     @click.stop="delete_Task(item)">
                  删除
                </div>
              </div>
            </Poptip>
          </div> -->
          <div class="more" @click="showMore(item.filetitle)">
            <Icon type="md-more" size="22" />
            <div
              :style="{ display: moreAct == item.filetitle ? 'block' : 'none' }"
            >
              <p @click.stop="send_Task(item)">
                <Icon type="md-create" style="margin-right: 0.15rem" />派发
              </p>
              <p @click.stop="delete_Task(item)">
                <Icon type="ios-trash" style="margin-right: 0.15rem" />删除
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="goTop" @click="scrollToSection" ref="goTop">回到顶部</div> -->
    <!--弹窗区-->
    <!--编辑文件夹信息-->
    <Modal
      v-model="editBox"
      width="360"
      class-name="editProject-center-modal"
      @on-cancel="closeEditProject"
    >
      <p slot="header" style="color: #409eff; text-align: center">
        <span>编辑文件夹名称</span>
      </p>
      <div style="padding: 10px 30px">
        <Input
          v-model.trim="editDir_name"
          show-word-limit
          maxlength="15"
        ></Input>
      </div>
      <div style="text-align: center" slot="footer">
        <Button
          type="info"
          @click="editProject"
          style="width: 200px; letter-spacing: 8px"
          >确认</Button
        >
      </div>
    </Modal>
    <!--添加文件夹-->
    <Modal
      v-model="addFolderBox"
      width="360"
      class-name="addFolder-center-modal"
      @on-cancel="closeAddFolder"
    >
      <p
        slot="header"
        style="color: #409eff; text-align: center; padding: 0 10px"
      >
        <span>新建文件夹</span>
      </p>
      <div style="padding: 10px 30px">
        <p>文件夹名称</p>
        <Input
          v-model.trim="addFolder_name"
          show-word-limit
          maxlength="15"
          style="width: 250px"
        ></Input>
      </div>
      <div style="text-align: center" slot="footer">
        <Button
          type="info"
          @click="addFolderFn"
          style="width: 200px; letter-spacing: 8px"
          >确认</Button
        >
      </div>
    </Modal>
    <!-- 任务派发 -->
    <Modal
      v-model="sendModalshow"
      width="360"
      title="任务派发"
      @on-cancel="closeSend"
      @on-ok="sendTask"
    >
      <Select v-model="sendMember" not-found-text="团队下暂无在线成员">
        <Option
          v-for="item in memberList"
          :value="item.user_id"
          :key="item.user_id"
          >{{ item.name }}</Option
        >
      </Select>
    </Modal>
  </div>
</template>

<script>
import _ from 'lodash'
import Api from '@/utils/api.js'
export default {
  name: 'ProjectBar',

  props: {
    // ShowMarker: {
    //   type: Function,
    //   default: null,
    // },
    shouAirRoute: {
      type: Function,
      default: null,
    },
  },

  data: function () {
    return {
      dir_Info:{},
      user_info: {},
      userType: 1,
      newProjectShou: true,
      searchMsg: '',
      teamPitchOn: [],
      teamData: [],
      listStatus: true,
      listData: [], //文件夹列表数据
      taskData: [], //任务列表数据
      noData: false,
      editBox: false, //编辑弹框
      editDir_name: '',
      editProjectOriginalDataID: '',
      placeholder: '搜索您的项目',
      selectedTeamData: '',
      PageState: 0, //0.文件夹和任务列表 //1.进入文件夹里的任务列表
      TeamID: -1,
      addFolderBox: false, //添加文件夹
      addFolder_name: '',
      dirID: -1,
      loading: true, // 加载效果
      sendModalshow: false,
      send_Task_id: 0, // 需发送的任务id
      send_Task_type: '', // 需发送的任务类型
      send_Task_name: '',
      sendMember: 0, // 接收任务的飞手
      memberList: [], // 当前团队成员列表
      moreAct: '-1',
    }
  },

  //   watch: {
  //     TeamID(val) {
  //       console.log(val);
  //     },
  //   },
  methods: {
    showMore(e) {
      if (this.moreAct == e) {
        this.moreAct = '-1'
      } else {
        this.moreAct = e
      }
    },

    // 搜索项目
    searchProjectFn() {
      if (this.dirID == -1) {
        this.getAirRouteList(this.selectedTeamData, this.searchMsg)
      } else {
        this.IntoDir(this.TeamID, this.dirID)
      }
    },
    // 加载团队数据
    getTeamData(id) {
      this.$post(Api.teamList(), {
        team_id: id,
      }).then((res) => {
        if (res.code == 1) {
          this.teamData = []
          res.data.teamlist.forEach((item) => {
            this.teamData.push({
              name: item.team_name,
              color: item.comment,
              id: item.id,
            })
          })
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    // 监听滚动距离
    // scrollEvent() {
    //   var top = this.$refs.ProjectBar.scrollTop;
    //   if (top >= 650) {
    //     this.$refs.goTop.style.display = "block";
    //   } else {
    //     this.$refs.goTop.style.display = "none";
    //   }
    // },
    // 回到顶部
    // scrollToSection() {
    //   document
    //     .getElementById("topAnchor")
    //     .scrollIntoView({ behavior: "smooth" });
    // },
    // 编辑弹框显示
    // 编辑项目
    editShou(item) {
      this.editBox = true
      this.editProjectOriginalDataID = item.id
      this.editDir_name = item.dir_name
    },
    // 提交编辑项目
    editProject() {
      if (this.editDir_name == '') {
        this.$Message.warning('文件夹名称不能为空')
        return false
      }
      this.$post(Api.editTaskDir(), {
        client: 'web',
        dir_name: this.editDir_name,
        dir_id: this.editProjectOriginalDataID,
      }).then((res) => {
        // console.log(res);
        if (res.code == 1) {
          this.$Message.success(res.msg_customer)
          this.getAirRouteList(this.TeamID)
          this.editBox = false
          this.closeEditProject()
        } else {
          this.$Notice.error({
            title: '提示',
            desc: res.msg_customer,
          })
        }
      })
    },
    // 关闭了编辑弹窗
    closeEditProject() {
      this.editProjectOriginalDataID = ''
      this.editDir_name = ''
    },

    //删除文件夹
    delete_Level_2(item) {
      console.log(item)
      this.$Modal.confirm({
        title: '删除文件夹',
        content: `是否删除  ${item.dir_name}  此文件夹,删除后数据无法恢复,谨慎删除!`,
        onOk: () => {
          this.$post(Api.deleteTaskDir(), {
            dir_id: item.id,
            client: 'web',
          }).then((res) => {
            console.log('删除文件夹', res)
            if (res.code == 1) {
              this.$Message.success(res.msg_customer)
              this.getAirRouteList(this.TeamID)
            } else {
              this.$Message.error(res.msg_customer)
            }
          })
        },
        onCancel: () => {},
      })
    },
    // 获取文件夹和任务列表数据
    getAirRouteList(id, task_name = '') {
      // this.loading = true
      this.moreAct = -1
      // 任务需后期修改，暂时取消-1获取全部
      if (id == -1 || id == '') {
        id = this.user_info.team_id
      }
      let params = {
        client: 'web',
        dir_id: this.dirID,
        team_id: id,
        task_name: task_name,
      }
      // if (this.dirID == -1 && this.$CSTJ) {
      //   params = {
      //     client: "web",
      //     team_id: id,
      //     task_name: task_name,
      //   };
      // }
      this.TeamID = id
      this.$post(Api.loadTaskList(), params).then((res) => {
        if (res.code == 1) {
          if (!res.data.dirList) {
            res.data.dirList = []
          }
          if (!res.data.tasklist) {
            res.data.tasklist = []
          }
          this.listData = []
          this.taskData = []
          if (res.data.dirList.length === 0 && res.data.tasklist.length === 0) {
            this.noData = true
            // this.ShowMarker(res.data.tasklist);
          } else {
            this.noData = false
            if (res.data.dirList !== []) {
              this.listData = res.data.dirList
            }
            if (this.dirID === -1) {
              if (res.data.tasklist !== []) {
                res.data.tasklist.slice(0, 5).forEach((item) => {
                  if (item.dir_id != 0) {
                    this.taskData.push(item)
                  }
                })
              }
            }
            // 在地图上显示项目地点
            // this.ShowMarker(res.data.tasklist);
            // this.IntoDir(this.dir_Info.team,this.dir_Info.id)
            this.$store.state.ProjectListType = 2
          }
          this.loading = false

          // console.log("加载了任务信息");
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    //进入文件夹
    IntoDir(team, id) {
      this.loading = true
      this.TeamID = team
      this.dirID = id
      sessionStorage.setItem(
        'dirInfo',
        JSON.stringify({ team: this.TeamID, id: this.dirID })
      )
      this.$post(Api.loadTaskList(), {
        task_name: this.searchMsg,
        client: 'web',
        team_id: team,
        dir_id: id,
      }).then((res) => {
        if (res.code == 1) {
          this.PageState = 1
          if (!res.data.tasklist) {
            res.data.tasklist = []
          }
          this.listData = []
          this.taskData = [] //  任务列表
          if (res.data.tasklist.length === 0) {
            if (this.searchMsg == '') {
              this.noData = true
            } else {
              this.searchMsg = ''
              this.IntoDir(this.TeamID, this.dirID)
            }
          } else {
            this.noData = false

            res.data.tasklist.forEach((item) => {
              this.taskData.push(item)
            })
            console.log(' this.taskData', this.taskData)
            console.log('listData', this.listData.length)

            // console.log("加载了任务信息");
            // 在地图上显示项目地点
            // this.ShowMarker(res.data.tasklist);
            this.$store.state.ProjectListType = 2
          }
          this.loading = false
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    //返回文件夹和任务列表
    getBackDir() {
      sessionStorage.removeItem('dirInfo')
      this.dirID = -1
      this.searchMsg = ''
      this.getAirRouteList(this.selectedTeamData)
      this.PageState = 0
    },
    // 点击列表进入任务
    IntoheTask(item) {
      // console.log("点击列表进入任务",item);
      sessionStorage.setItem('getTaskData', JSON.stringify(item))
      sessionStorage.setItem('ProjectTaskinfo', JSON.stringify(item))
      this.$router.push({
        path: '/index/PT',
      })
    },
    // 新建任务
    newTaskFn() {
      this.$router.push({
        path: '/route-specification',
        query: {
          team_id: this.TeamID ? this.TeamID : this.user_info.team_id,
          dir_id: this.dirID == -1 ? this.listData[0].id : this.dirID,
        },
      })
    },
    //添加文件夹
    addFolderFn() {
      if (this.addFolder_name == '') {
        this.$Message.warning('文件夹名称不能为空')
        return false
      }
      this.$post(Api.createTaskDir(), {
        client: 'web',
        team_id: this.TeamID ? this.TeamID : this.user_info.team_id,
        dir_name: this.addFolder_name,
      }).then((res) => {
        // console.log("团队数据",res);
        if (res.code == 1) {
          this.addFolderBox = false
          this.addFolder_name = ''
          this.getAirRouteList(this.TeamID)
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    //关闭添加文件夹窗口
    closeAddFolder() {
      this.addFolder_name = ''
    },
    // 航线任务下发
    send_Task(item) {
      this.sendModalshow = true
      this.send_Task_id = item.id
      this.send_Task_name = item.filetitle
      this.send_Task_type = item.tasktype
      this.$post(Api.getTeamUser(), {
        team_id: this.TeamID,
      }).then((res) => {
        if (res.code == 1) {
          this.memberList = res.data.filter(
            (e) => e.user_id != this.user_info.user_id && e.is_online == 1
          )
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },

    sendTask() {
      /**
       * this.sendMember 接收人id
       * this.send_Task_id 任务id
       * this.user_info.user_id 发送人id
       * 航线任务:airroute
       * 建图任务:figure
       */
      console.log(this.sendMember)
      this.$post(Api.taskIssue(), {
        fromUserId: this.user_info.user_id,
        taskId: this.send_Task_id,
        toUserId: this.sendMember,
        type: this.send_Task_type,
        dirId: this.dirID,
        teamId: this.TeamID,
        taskName: this.send_Task_name,
      }).then((res) => {
        if (res.code == 1) {
          this.$Message.success(res.msg_customer)
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    closeSend() {
      this.memberList = []
      this.sendMember = 0
    },
    // 删除航线
    delete_Task(item) {
      this.$Modal.confirm({
        title: '删除任务',
        content: `是否删除  ${item.filetitle}  此任务,删除后数据无法恢复,谨慎删除!`,
        onOk: () => {
          if (item.tasktype == 'figure') {
            //建图
            this.$post(Api.buildFigureDel(), {
              bf_id: item.id,
            }).then((res) => {
              console.log('删除建图', res)
              if (res.code == 1) {
                this.dirID = -1
                // this.getAirRouteList(this.TeamID)
                this.IntoDir(this.dir_Info.team,this.dir_Info.id)
              } else {
                this.$Message.error(res.msg_customer)
              }
            })
          } else {
            this.$post(Api.airRouteAllDel(), {
              client: 'web',
              air_route_id: item.id,
            }).then((res) => {
              console.log('删除航线', res)
              if (res.code == 1) {
                this.dirID = -1
                // this.getAirRouteList(this.TeamID)
                this.IntoDir(this.dir_Info.team,this.dir_Info.id)
              } else {
                this.$Message.error(res.msg_customer)
              }
            })
          }
        },
        onCancel: () => {},
      })
    },
  },

  mounted() {
    this.user_info = JSON.parse(sessionStorage.getItem('user_info'))
    this.userType = sessionStorage.getItem('userType')
    let dirInfo = JSON.parse(sessionStorage.getItem('dirInfo'))
    this.dir_Info = dirInfo
    if (
      (sessionStorage.getItem('team_id') === null || this,
      this.user_info.permission == 0)
    ) {
      this.TeamID = this.user_info.team_id
      if (dirInfo) {
        this.IntoDir(dirInfo.team, dirInfo.id)
      } else {
        this.getAirRouteList(this.TeamID)
      }
    } else {
      this.TeamID = Number(sessionStorage.getItem('team_id'))
      this.selectedTeamData = Number(sessionStorage.getItem('team_id'))
      if (dirInfo) {
        this.IntoDir(dirInfo.team, dirInfo.id)
      } else {
        this.getAirRouteList(this.TeamID)
      }
    }
    // this.TeamID = this.user_info.team_id;

    this.$_bus.$off('change_Team').$on('change_Team', (value) => {
      //   this.dirID = -1;
      //   this.getAirRouteList(value);
      this.selectedTeamData = value
      //   if (value == -1) {
      this.getBackDir()
      //   }
    })
  },
}
</script> 

<style scoped lang="scss">
#ProjectBar {
  position: relative;
  width: 100%;
  height: calc(100vh - 50px);
  padding-bottom: 3rem;
  overflow: auto;
  .getBack {
    background-color: $xf_hui1;
    width: 100%;
    height: 0.5rem;
    padding-left: 0.2rem;
    cursor: pointer;
    border-bottom: 1px solid #707070;
    i {
      font-size: 0.25rem;
      color: $white;
      margin-right: 0.06rem;
      line-height: 0.5rem;
    }
    span {
      font-size: 0.2rem;
      color: $white;
      line-height: 0.5rem;
    }
  }
  .newBox {
    // width: 100%;
    position: relative;
    padding: 0.2rem 2.3rem 0;
    display: flex;
    justify-content: space-between;
    button {
      width: 1.25rem;
      height: 0.36rem;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
      border-radius: 0.04rem;
      cursor: pointer;
    }

    span {
      font-size: 0.13rem;
      text-align: center;
      line-height: 0.36rem;
    }
    .btn {
      float: left;
      margin: 5px 10px;
      cursor: pointer;
      padding: 0px 15px;
      background: #2196f3;
      color: $white;
      border: 1px solid #2196f3;
    }
    .btn:last-child {
      background: $xf_hui2;
      border: 1px solid $black;
    }
    .btn:hover {
      border: 1px solid $white;
    }
    .btn.borderRadius {
      border-radius: 18%/50%;
    }
    .searchProject {
      width: 2.4rem;
      position: relative;
      left: 0.2rem;
      // transform: translateX(-50%);
      // input {

      //   background-color: $xf_hui1 !important;// transparent #161516
      //   border: none !important;
      // }
      // input:focus::-webkit-input-placeholder {
      //   color: transparent;
      // }
      // i {
      //   position: absolute;
      //   top: 0.06rem;
      //   right: 0;
      //   font-size: 0.2rem;
      //   color: $font_color_1;
      // }
    }
  }

  .ProjectList {
    width: 100%;
    position: relative;
    padding: 0 2.3rem;
    // left: 50%;
    // transform: translateX(-50%);
    // margin-top: 0.3rem;
    z-index: 10;
    .noData {
      padding-top: 0.3rem;
      h1 {
        width: 100%;
        text-align: center;
        color: #999999;
        font-size: 0.16rem;
      }
    }
    > h1 {
      font-size: 0.12rem;
      color: #c4c4c4;
      margin-bottom: 0.1rem;
      margin-right: 0.2rem;
      position: absolute;
      right: 0.1rem;
      i {
        position: absolute;
        top: 0.03rem;
        left: 0.21rem;
        font-size: 0.25rem;
        color: #c4c4c4;
      }
    }
    // .ListBox1 {
    //   overflow-y: auto;
    //   height: calc(100vh - 2rem);
    // }
    .ListItem {
      width: 2.36rem;
      height: 1.65rem;
      margin: 0.18rem;
      float: left;
      border: 1px solid $xf_hei1;
      background-color: $xf_hei1;

      cursor: pointer;
      position: relative;
      .ItemLeft {
        width: 100%;
        height: 1.2rem;
        position: relative;
        line-height: 1.5rem;
        text-align: center;
      }
      .ItemContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 0.16rem;
        h2 {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 0.14rem;
          color: $font_color_1;
        }
        // .ItemRight {
        //   .more_i {
        //     display: block;
        //     cursor: pointer;
        //     width: 0.3rem;
        //     line-height: 0.34rem;
        //     font-size: 0.3rem;
        //     color: #ccc;
        //   }
        //   .api {
        //     margin: 4px 0;
        //     .Divider {
        //       width: 100%;
        //       height: 1px;
        //       margin: 6px 0;
        //       // background-color: #c5c8ce;
        //     }
        //     .Button {
        //       cursor: pointer;
        //     }
        //   }
        // }
        .more {
          position: absolute;
          color: #a0a0a0;
          top: 1.3rem;
          right: 0.05rem;
          > div {
            display: none;
            position: absolute;
            width: 1.06rem;
            height: 1rem;
            // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            border: 1px solid #ccc;
            border-radius: 4px;
            background-color: $xf_hei4;
            top: 25px;
            right: 4px;
            z-index: 9;
            overflow: hidden;
            p {
              width: 100%;
              color: $font_color_1;
              height: 0.5rem;
              font-size: 0.14rem;
              line-height: 0.5rem;
              text-align: center;
            }
            p:hover {
              background-color: $xf_hui7_hover;
            }
          }
        }
        // .more:hover div {
        //   display: block;
        // }
        .more:hover {
          height: 22px;
          border-radius: 50%;
          background-color: $xf_hui3;
        }
      }
    }
    .near {
      clear: both;
      color: $white;
      padding: 0.3rem 0 0.15rem;
      border-bottom: 1px solid #707070;
      margin: 30px 0;
    }
    .taskItem {
      width: 2.36rem;
      height: 1.65rem;
      margin: 0.18rem;
      float: left;
      border: 1px solid $xf_hei1;
      background-color: $xf_hei1;
      border-radius: 7px;
      //   display: flex;
      //   align-items: center;
      cursor: pointer;
      // margin-bottom: 2px;
      position: relative;
      .ItemLeft {
        height: 1.11rem;
        // position: relative;
        // margin-right: 16px;
        img {
          width: 2.34rem;
          height: 1.11rem;
          border-radius: 7px 7px 0 0;
        }
        p {
          height: 1.11rem;
          line-height: 1.11rem;
          text-align: center;
          color: #aaa;
          font-size: 0.16rem;
        }
        span {
          position: absolute;
          top: 0.1rem;
          left: 0.1rem;
          background: rgba(0, 0, 0, 0.6);
          color: #fff;
          display: inline-block;
          font-size: 0.14rem;
          padding: 0.04rem 0.1rem;
        }
        .newImgTip {
          position: absolute;
          right: 8px;
          top: 8px;
          width: 36px;
          font-size: 12px;
          background: rgba(179, 0, 27, 0.8);
          color: #fff;
        }
      }
      .ItemContent {
        width: 100%;
        height: 0.55rem;
        padding: 0 0.1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h2 {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 0.14rem;
          color: $font_color_1;
          height: 0.24rem;
        }
        h3 {
          width: 100%;
          font-size: 0.12rem;
          color: #a0a0a0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      // .ItemRight {
      //   position: absolute;
      //   bottom: 0.15rem;
      //   right: 0.1rem;
      //   // transform: translateY(-50%);
      //   .more_i {
      //     display: block;
      //     cursor: pointer;
      //     width: 0.3rem;
      //     line-height: 0.34rem;
      //     font-size: 0.3rem;
      //     color: #ccc;
      //   }
      //   .api {
      //     margin: 0.04rem 0;
      //     .Divider {
      //       width: 100%;
      //       height: 1px;
      //       margin: 6px 0;
      //       // background-color: #c5c8ce;
      //     }
      //     .Button {
      //       cursor: pointer;
      //     }
      //   }
      // }
      .more {
        position: absolute;
        color: #a0a0a0;
        top: 1.3rem;
        right: 0.05rem;
        > div {
          display: none;
          position: absolute;
          width: 1.06rem;
          height: 1rem;
          // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          border: 1px solid #ccc;
          border-radius: 4px;
          background-color: $xf_hei4;
          top: 25px;
          right: 4px;
          z-index: 9;
          overflow: hidden;
          p {
            width: 100%;
            color: $font_color_1;
            height: 0.5rem;
            font-size: 0.14rem;
            line-height: 0.5rem;
            text-align: center;
          }
          p:hover {
            background-color: $xf_hui7_hover;
          }
        }
      }
      // .more:hover div {
      //   display: block;
      // }
      .more:hover {
        height: 22px;
        border-radius: 50%;
        background-color: $xf_hui3;
      }
    }
    .ListItem:hover,
    .taskItem:hover {
      border: 1px solid $xf_hui3;
    }
  }

  /deep/.ivu-poptip-popper {
    padding-left: 55px;
  }
}
#ProjectBar::-webkit-scrollbar {
  display: none;
}
</style>
